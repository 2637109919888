<template>
  <footer>
    <section
      id="footer-section"
      class="bg-primary text-white"
      style="padding: 50px 0 60px 0"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h3 class="section-heading">
              Sign Up for Updates
            </h3>
            <hr class="light">
          </div>
        </div>
      </div>
      <div class="container">
        <form
          id="mc-embedded-subscribe-form"
          action="https://cybernabo.us11.list-manage.com/subscribe/post?u=ed0e25207927eeb57ac697962&amp;id=d2e0b803d8"
          method="post"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <div class="row">
            <div class="col-md-3">
              <input
                type="text"
                name="FNAME"
                class="form-control"
                placeholder="First Name*"
              >
            </div>
            <div class="col-md-3">
              <input
                type="text"
                name="LNAME"
                class="form-control"
                placeholder="Last Name"
              >
            </div>
            <div class="col-md-4">
              <input
                type="text"
                name="EMAIL"
                class="form-control"
                placeholder="Email*"
              >
            </div>
            <div class="col-md-2">
              <div
                id="mce-responses"
                class="clear"
              >
                <div
                  id="mce-error-response"
                  class="response"
                  style="display: none"
                />
                <div
                  id="mce-success-response"
                  class="response"
                  style="display: none"
                />
              </div>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div
                style="position: absolute; left: -5000px; display: none; visibility: hidden"
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_ed0e25207927eeb57ac697962_d2e0b803d8"
                  tabindex="-1"
                  value=""
                >
              </div>
              <div class="clear">
                <button
                  id="mc-embedded-subscribe"
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  class="btn btn-light btn-block"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
    <section
      id="funding-section"
      class="bg-dark"
    >
      <div class="container call-to-action">
        <div class="row">
          <div class="col-lg-8 mx-auto text-center">
            <h2 class="section-heading text-white">
              Funding
            </h2>
            <hr class="light">
            <p class="mb-3 mt-4">
              <a
                href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=1637076"
              ><img
                width="166"
                height="167"
                src="../assets/img/nsf_logo_shading.png"
                class="image wp-image-38 attachment-full size-full"
                alt="NSF Logo"
                style="max-width: 100%; height: auto"
                sizes="(max-width: 166px) 100vw, 166px"
              ></a>
            </p>
            <p class="text-faded mb-5">
              With support from the National Science Foundation, Grant Numbers 1637076 and 1439389. This project is
              led by <a href="https://nsidc.org">NSIDC</a>, with development led by the
              <a href="http://www.digitalantiquity.org">Center for Digital Antiquity</a> (Arizona State University),
              and user interface developed by the
              <a href="http://cast.uark.edu">Center for Advanced Spatial Technologies</a> (University of Arkansas).
            </p>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>
